import React, { useContext, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie';
import { Locale, Window, Title } from '../App';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Terms() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);

    const [onPage, setOnPage] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("terms");
        setAncestors([
            { name: translations[locale].footer.terms, url: "/terms" },
        ]);
    }, []);

    return (
        <div className={`home init ${onPage ? "init--achieved" : ""}`}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].footer.terms}
                    </title>
                    <meta name="description"
                        content={translations[locale].terms.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].terms.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className={`home__content container__content home__content--page ${currentBreakpoint}`}>
                    <div className="home__content__block home__content__block--page">
                        <div className="useful">
                            <h1 className="useful__title">
                                {translations[locale].footer.terms}
                            </h1>
                            <div className="useful__blocks">
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        1. {translations[locale].terms.introduction.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.introduction.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        2. {translations[locale].terms.access.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.access.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        3. {translations[locale].terms.responsibility.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.responsibility.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        4. {translations[locale].terms.intellectual_property.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.intellectual_property.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        5. {translations[locale].terms.personal_data.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.personal_data.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        6. {translations[locale].terms.cookies.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {locale === "fr" ?
                                                <React.Fragment>
                                                    Lors de votre navigation sur le site Bracson, des cookies peuvent être déposés sur votre terminal pour améliorer l’expérience utilisateur.
                                                    Vous avez la possibilité de gérer vos préférences en matière de cookies à tout moment en cliquant sur l'icône flottante en bas à gauche de votre écran.
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    While browsing the Bracson website, cookies may be placed on your device to improve the user experience.
                                                    You can manage your cookie preferences at any time by clicking on the floating icon at the bottom left of your screen.
                                                </React.Fragment>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        7. {translations[locale].terms.external_links.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.external_links.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        8. {translations[locale].terms.modification.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.modification.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        9. {translations[locale].terms.law.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].terms.law.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.disclaimer.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.disclaimer.text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}