import React, { useContext, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie';
import { Locale, Window, Title } from '../App';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Cookies() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);

    const [onPage, setOnPage] = useState(false);
    const [cookies, setCookie] = useCookies(['user']);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("cookies");
        setAncestors([
            { name: translations[locale].footer.cookie, url: "/cookies" },
        ]);
    }, []);

    const acceptCookies = () => {
        setCookie('userConsent', true, { path: '/', maxAge: 3600 * 24 * 365 });
        setCookie('analyticsCookies', true, { path: '/', maxAge: 3600 * 24 * 365 });
        setCookie('recaptchaCookies', true, { path: '/', maxAge: 3600 * 24 * 365 });
        window.location.reload();
    };

    const rejectCookies = () => {
        setCookie('userConsent', true, { path: '/', maxAge: 3600 * 24 * 365 });
        setCookie('analyticsCookies', false, { path: '/', maxAge: 3600 * 24 * 365 });
        setCookie('recaptchaCookies', false, { path: '/', maxAge: 3600 * 24 * 365 });
        window.location.reload();
    };

    return (
        <div className={`home init ${onPage ? "init--achieved" : ""}`}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].footer.cookie}
                    </title>
                    <meta name="description"
                        content={translations[locale].cookie.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].cookie.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className={`home__content container__content home__content--page ${currentBreakpoint}`}>
                    <div className="home__content__block home__content__block--page">
                        <div className="useful">
                            <h1 className="useful__title">
                                {translations[locale].footer.cookie}
                            </h1>
                            <div className="useful__blocks">
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].terms.introduction.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {locale === "fr" ?
                                                <React.Fragment>
                                                    Lors de votre navigation sur le site Bracson (<Link className="useful__link" to="/">https://www.bracson.com/</Link>), des cookies peuvent être déposés sur votre terminal pour améliorer l’expérience utilisateur.
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    While browsing the Bracson website (<Link className="useful__link" to="/">https://www.bracson.com/</Link>), cookies may be placed on your device to improve the user experience.
                                                </React.Fragment>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title"
                                        dangerouslySetInnerHTML={{ __html: translations[locale].cookie.definition.title }}>
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].cookie.definition.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].cookie.type.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text"
                                            dangerouslySetInnerHTML={{ __html: translations[locale].cookie.type.text }}>
                                        </p>
                                        <p className="useful__blocks__block__body__text--title">
                                            1. {translations[locale].cookie.type.title_1}<br />
                                        </p>
                                        <p className="useful__blocks__block__body__text"
                                            dangerouslySetInnerHTML={{ __html: translations[locale].cookie.type.text_1 }}>
                                        </p>
                                        <p className="useful__blocks__block__body__text--title">
                                            2. {translations[locale].cookie.type.title_2}<br />
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].cookie.type.text_2}
                                        </p>
                                        <p className="useful__blocks__block__body__text--title">
                                            3. {translations[locale].cookie.type.title_3}<br />
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].cookie.type.text_3}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].cookie.gestion.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text"
                                            dangerouslySetInnerHTML={{ __html: translations[locale].cookie.gestion.text_2 }}>
                                        </p>
                                        <div className="useful__blocks__block__body__buttons">
                                            <a className="useful__blocks__block__body__buttons__link"
                                                href="https://support.google.com/accounts/answer/32050?hl=fr&co=GENIE.Platform%3DDesktop"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Google Chrome
                                            </a>
                                            <a className="useful__blocks__block__body__buttons__link"
                                                href="https://support.mozilla.org/fr/kb/effacer-cookies-donnees-site-firefox"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Mozilla Firefox
                                            </a>
                                            <a className="useful__blocks__block__body__buttons__link"
                                                href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Safari
                                            </a>
                                            <a className="useful__blocks__block__body__buttons__link"
                                                href="https://support.microsoft.com/fr-fr/help/4027947/microsoft-edge-delete-cookies"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                Microsoft Edge
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].cookie.conservation.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].cookie.conservation.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].cookie.droit.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text"
                                            dangerouslySetInnerHTML={{ __html: translations[locale].cookie.droit.text }}>
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].cookie.modification.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].cookie.modification.text}
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.disclaimer.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.disclaimer.text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}