import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Breadcrumb({ ancestors }) {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    const [showLeftShadow, setShowLeftShadow] = useState(false);
    const [showRightShadow, setShowRightShadow] = useState(true);
    const scrollRef = useRef(null);

    const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

        setShowLeftShadow(scrollLeft > 0);
        setShowRightShadow(scrollLeft + clientWidth < scrollWidth);
    };

    return (
        <div className="breadcrumb">
            <div className={`breadcrumb__shadow-left ${showLeftShadow ? "active" : ""}`} />
            <div className={`breadcrumb__shadow-right ${showRightShadow ? "active" : ""}`} />
            <div className={`breadcrumb__scroll ${currentBreakpoint}`} ref={scrollRef} onScroll={handleScroll}>
                <Link className="breadcrumb__scroll__item"
                    to="/"
                    title={translations[locale].header.home}>
                    {translations[locale].header.home}
                </Link>
                {ancestors.map((ancestor, index) => (
                    <React.Fragment key={index}>
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                        {(index + 1) === ancestors.length ?
                            <span className="breadcrumb__scroll__item breadcrumb__scroll__item--active">
                                {ancestor.name}
                            </span>
                            : <Link className="breadcrumb__scroll__item"
                                to={ancestor.url}
                                title={ancestor.name}>
                                {ancestor.name}
                            </Link>
                        }
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}