import React, { useContext, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie';
import { Locale, Window, Title } from '../App';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Legal() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);

    const [onPage, setOnPage] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("legal");
        setAncestors([
            { name: translations[locale].footer.legal_notice, url: "/legal" },
        ]);
    }, []);

    return (
        <div className={`home init ${onPage ? "init--achieved" : ""}`}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].footer.legal_notice}
                    </title>
                    <meta name="description"
                        content={translations[locale].legal.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].legal.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className={`home__content container__content home__content--page ${currentBreakpoint}`}>
                    <div className="home__content__block home__content__block--page">
                        <div className="useful">
                            <h1 className="useful__title">
                                {translations[locale].footer.legal_notice}
                            </h1>
                            <div className="useful__blocks">
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.site_information.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {locale === "fr" ?
                                                <React.Fragment>
                                                    Le site internet Bracson
                                                    (<Link class="useful__link" to="/">https://bracson.com</Link>)
                                                    est édité par&nbsp;:
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    The Bracson website
                                                    (<Link class="useful__link" to="/">https://bracson.com</Link>)
                                                    is published by:
                                                </React.Fragment>
                                            }
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.site_information.responsable}
                                            <span className="useful__blocks__block__body__text__span">
                                                Julien Bracard
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.site_information.address}
                                            <span className="useful__blocks__block__body__text__span">
                                                11 Boulevard Achille Marcel<br />
                                                13010 Marseille<br />
                                                France
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.site_information.email_address}
                                            <span className="useful__blocks__block__body__text__span">
                                                bracson.contact[a]gmail.com
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.site_information.phone_number}
                                            <span className="useful__blocks__block__body__text__span">
                                                +33 6 30 92 41 21
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.site_information.siret}
                                            <span className="useful__blocks__block__body__text__span">
                                                953 531 217 00016
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.site_information.vat_number}
                                            <span className="useful__blocks__block__body__text__span">
                                                FR31953531217
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.hosting_provider.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text"
                                            dangerouslySetInnerHTML={{ __html: translations[locale].legal.hosting_provider.text }}>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.hosting_provider.hosting_provider}
                                            <span className="useful__blocks__block__body__text__span">
                                                Google Firebase
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.hosting_provider.address}
                                            <span className="useful__blocks__block__body__text__span">
                                                Google Ireland Limited<br />
                                                Gordon House, Barrow Street, Dublin 4<br />
                                                Ireland
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.hosting_provider.phone_number}
                                            <span className="useful__blocks__block__body__text__span">
                                                +353 1 543 1000
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text"
                                            dangerouslySetInnerHTML={{ __html: translations[locale].legal.hosting_provider.text_2 }}>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.hosting_provider.stockage}
                                            <span className="useful__blocks__block__body__text__span">
                                                Bunny.net
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.hosting_provider.address}
                                            <span className="useful__blocks__block__body__text__span">
                                                BunnyWay d.o.o.<br />
                                                Cesta komandanta Staneta 4A<br />
                                                1215 Medvode<br />
                                                Slovenia
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.hosting_provider.phone_number}
                                            <span className="useful__blocks__block__body__text__span">
                                                +386 41 666 845
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.intellectual_property.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {locale === "fr" ?
                                                <React.Fragment>
                                                    Tous les contenus présents sur le site Bracson
                                                    (<Link className="useful__link" to="/">https://www.bracson.com/</Link>),
                                                    incluant, de manière non exhaustive, les textes, images, vidéos, logos et icônes, sont la propriété de Bracson ou de leurs auteurs respectifs. Toute reproduction, distribution ou utilisation de ces contenus est interdite sans autorisation préalable.
                                                </React.Fragment>
                                                : <React.Fragment>
                                                    All content available on the Bracson website
                                                    (<Link className="useful__link" to="/">https://www.bracson.com/</Link>),
                                                    including, but not limited to, texts, images, videos, logos, and icons, are the property of Bracson or their respective authors. Any reproduction, distribution, or use of this content is prohibited without prior authorization.
                                                </React.Fragment>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.credits.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.credits.design_development}
                                            <span className="useful__blocks__block__body__text__span">
                                                Benoît Ricard (<a className="useful__link"
                                                    href="https://www.linkedin.com/in/benoit-ricard-dev/"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    LinkedIn
                                                </a>)
                                            </span>
                                        </p>
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.credits.photos_and_videos}
                                            <span className="useful__blocks__block__body__text__span">
                                                Julien Tournissa (<a className="useful__link"
                                                    href="https://www.julientournissa.fr"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    www.julientournissa.fr
                                                </a>)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="useful__blocks__block">
                                    <h2 className="useful__blocks__block__title">
                                        {translations[locale].legal.disclaimer.title}
                                    </h2>
                                    <div className="useful__blocks__block__body">
                                        <p className="useful__blocks__block__body__text">
                                            {translations[locale].legal.disclaimer.text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}