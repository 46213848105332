import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Universes() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);
    const { universes } = useContext(Content);

    const [services, setServices] = useState(universes);
    const [query, setQuery] = useState("");

    const navigateTo = useNavigate("");

    const [onPage, setOnPage] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("universes");
        setAncestors([
            { name: translations[locale].universes.title, url: "/universes" },
        ]);
    }, []);

    useEffect(() => {
        setServices(universes.filter(uni => uni.title.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(query.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")) || uni.text.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(query.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, ""))));
    }, [query]);

    return (
        <div className={`home init ${onPage ? "init--achieved" : ""}`}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].universes.title}
                    </title>
                    <meta name="description"
                        content={translations[locale].universes.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].universes.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className={`home__content container__content home__content--page ${currentBreakpoint}`}>
                    <div className="home__content__block home__content__block--page">
                        <div className="universes">
                            <h1 className={`home__content__title ${currentBreakpoint}`}>
                                {translations[locale].universes.title}
                            </h1>
                            <input type="text"
                                className="universes__search"
                                placeholder={translations[locale].universes.search}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)} />
                            <div className={`bicyclette__gallery ${currentBreakpoint}`}>
                                {services.length === 0 ?
                                    <p className="bicyclette__gallery__noresult">
                                        {translations[locale].universes.no_result_found}
                                    </p>
                                    : <React.Fragment>
                                        {!["xl", "xxl"].includes(currentBreakpoint) ?
                                            <React.Fragment>
                                                <div className={`bicyclette__gallery__list ${currentBreakpoint}`}>
                                                    {services.slice(0, Math.ceil(services.length / 2)).map((universe, index) => (
                                                        <div key={index}
                                                            className={`home__content__universes__list__item universes__list__item ${currentBreakpoint}`}
                                                            onClick={() => navigateTo(universe.url)}>
                                                            <img className={`universes__list__item__picture image-loading ${currentBreakpoint}`}
                                                                src={universe.picture}
                                                                alt={universe.title}
                                                                loading="lazy" />
                                                            <div className="home__content__universes__list__item__info">
                                                                <h1 className="home__content__universes__list__item__info__title home__content__universes__list__item__info__title--universes">
                                                                    {universe.title}
                                                                </h1>
                                                                <p className="home__content__block__text home__content__universes__list__item__info__text"
                                                                    dangerouslySetInnerHTML={{ __html: universe.text }}>
                                                                </p>
                                                                <Link className="button button--arrow"
                                                                    to={universe.url}>
                                                                    <div className="button--arrow__icon">
                                                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                                    </div>
                                                                    {translations[locale].home.our_universes.button}
                                                                </Link>
                                                                {universe.new &&
                                                                    <div className="ribbon">
                                                                        <div className="ribbon__text">
                                                                            {translations[locale].universes.new}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={`bicyclette__gallery__list ${currentBreakpoint}`}>
                                                    {services.slice(Math.ceil(services.length / 2), services.length).map((universe, index) => (
                                                        <div key={index}
                                                            className={`home__content__universes__list__item universes__list__item ${currentBreakpoint}`}
                                                            onClick={() => navigateTo(universe.url)}>
                                                            <img className={`universes__list__item__picture image-loading ${currentBreakpoint}`}
                                                                src={universe.picture}
                                                                alt={universe.title}
                                                                loading="lazy" />
                                                            <div className="home__content__universes__list__item__info">
                                                                <h1 className="home__content__universes__list__item__info__title home__content__universes__list__item__info__title--universes">
                                                                    {universe.title}
                                                                </h1>
                                                                <p className="home__content__block__text home__content__universes__list__item__info__text"
                                                                    dangerouslySetInnerHTML={{ __html: universe.text }}>
                                                                </p>
                                                                <Link className="button button--arrow"
                                                                    to={universe.url}>
                                                                    <div className="button--arrow__icon">
                                                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                                    </div>
                                                                    {translations[locale].home.our_universes.button}
                                                                </Link>
                                                                {universe.new &&
                                                                    <div className="ribbon">
                                                                        <div className="ribbon__text">
                                                                            {translations[locale].universes.new}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <div className={`bicyclette__gallery__list ${currentBreakpoint}`}>
                                                    {services.slice(0, Math.ceil(services.length / 3)).map((universe, index) => (
                                                        <div key={index}
                                                            className={`home__content__universes__list__item universes__list__item ${currentBreakpoint}`}
                                                            onClick={() => navigateTo(universe.url)}>
                                                            <img className={`universes__list__item__picture image-loading ${currentBreakpoint}`}
                                                                src={universe.picture}
                                                                alt={universe.title}
                                                                loading="lazy" />
                                                            <div className="home__content__universes__list__item__info">
                                                                <h1 className="home__content__universes__list__item__info__title home__content__universes__list__item__info__title--universes">
                                                                    {universe.title}
                                                                </h1>
                                                                <p className="home__content__block__text home__content__universes__list__item__info__text"
                                                                    dangerouslySetInnerHTML={{ __html: universe.text }}>
                                                                </p>
                                                                <Link className="button button--arrow"
                                                                    to={universe.url}>
                                                                    <div className="button--arrow__icon">
                                                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                                    </div>
                                                                    {translations[locale].home.our_universes.button}
                                                                </Link>
                                                                {universe.new &&
                                                                    <div className="ribbon">
                                                                        <div className="ribbon__text">
                                                                            {translations[locale].universes.new}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={`bicyclette__gallery__list ${currentBreakpoint}`}>
                                                    {services.slice(Math.ceil(universes.length / 3), (services.length - Math.floor(services.length / 3))).map((universe, index) => (
                                                        <div key={index}
                                                            className={`home__content__universes__list__item universes__list__item ${currentBreakpoint}`}
                                                            onClick={() => navigateTo(universe.url)}>
                                                            <img className={`universes__list__item__picture image-loading ${currentBreakpoint}`}
                                                                src={universe.picture}
                                                                alt={universe.title}
                                                                loading="lazy" />
                                                            <div className="home__content__universes__list__item__info">
                                                                <h1 className="home__content__universes__list__item__info__title home__content__universes__list__item__info__title--universes">
                                                                    {universe.title}
                                                                </h1>
                                                                <p className="home__content__block__text home__content__universes__list__item__info__text"
                                                                    dangerouslySetInnerHTML={{ __html: universe.text }}>
                                                                </p>
                                                                <Link className="button button--arrow"
                                                                    to={universe.url}>
                                                                    <div className="button--arrow__icon">
                                                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                                    </div>
                                                                    {translations[locale].home.our_universes.button}
                                                                </Link>
                                                                {universe.new &&
                                                                    <div className="ribbon">
                                                                        <div className="ribbon__text">
                                                                            {translations[locale].universes.new}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={`bicyclette__gallery__list ${currentBreakpoint}`}>
                                                    {services.slice((universes.length - Math.floor(services.length / 3)), services.length).map((universe, index) => (
                                                        <div key={index}
                                                            className={`home__content__universes__list__item universes__list__item ${currentBreakpoint}`}
                                                            onClick={() => navigateTo(universe.url)}>
                                                            <img className={`universes__list__item__picture image-loading ${currentBreakpoint}`}
                                                                src={universe.picture}
                                                                alt={universe.title}
                                                                loading="lazy" />
                                                            <div className="home__content__universes__list__item__info">
                                                                <h1 className="home__content__universes__list__item__info__title home__content__universes__list__item__info__title--universes">
                                                                    {universe.title}
                                                                </h1>
                                                                <p className="home__content__block__text home__content__universes__list__item__info__text"
                                                                    dangerouslySetInnerHTML={{ __html: universe.text }}>
                                                                </p>
                                                                <Link className="button button--arrow"
                                                                    to={universe.url}>
                                                                    <div className="button--arrow__icon">
                                                                        <FontAwesomeIcon icon="fa-solid fa-plus" />
                                                                    </div>
                                                                    {translations[locale].home.our_universes.button}
                                                                </Link>
                                                                {universe.new &&
                                                                    <div className="ribbon">
                                                                        <div className="ribbon__text">
                                                                            {translations[locale].universes.new}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}