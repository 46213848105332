import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams, Navigate } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCookies } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Lightbox from './Lightbox';
import Wave from '../assets/wave.png';

export default function Service() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);
    const { universes } = useContext(Content);
    const { id } = useParams();

    const [cookies] = useCookies(['youtubeCookies']);
    const [index, setIndex] = useState(1);
    const [displayLightbox, setDisplayLightbox] = useState(false);
    const [onPage, setOnPage] = useState(false);
    const [previous, setPrevious] = useState(null);
    const [next, setNext] = useState(null);

    const service = universes.find(item => item.id === id);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(false);
        setPage(service?.page);
        setAncestors([
            { name: translations[locale].universes.title, url: "/universes" },
            { name: service?.title, url: service?.url },
        ]);

        setTimeout(() => {
            setOnPage(true);
        }, 1);
    }, [id]);

    if (!service) {
        return <Navigate to="/not-found" />;
    }

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {service?.title}
                    </title>
                    <meta name="description"
                        content={service?.meta_description} />
                    <meta name="og:description"
                        content={service?.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className={`home init ${onPage ? "init--achieved" : ""}`}>
                <div className="home__picture">
                    <img className="home__picture__image home__picture__image--page image-loading"
                        src={service?.picture}
                        alt={service?.title} />
                    <div className={`home__picture__wave ${currentBreakpoint}`}>
                        <img className="home__picture__wave__image"
                            src={Wave}
                            alt="Wave" />
                    </div>
                </div>
                <div className="container">
                    <div className={`home__content home__content--service container__content home__content--page ${currentBreakpoint}`}>
                        <div className="home__content__block">
                            <div className={`home__content__block__flex ${service?.page !== "bicyclette" ? "home__content__block__flex--generic" : ""}`}>
                                {service?.page === "bicyclette" &&
                                    <div className={`home__content__logo__bicyclette ${currentBreakpoint}`}>
                                        <img className={`home__content__logo home__content__logo__bicyclette--bicyclette ${currentBreakpoint}`}
                                            src="https://bracson.b-cdn.net/Logo/logo_bicyclette_svg.svg"
                                            alt="Bicyclette Smoothie Énergie" />
                                    </div>
                                }
                                <h1 className={`home__content__title ${currentBreakpoint} ${service?.page !== "bicyclette" ? "home__content__title--generic" : ""}`}>
                                    {service?.title}
                                </h1>
                            </div>
                            {service?.page === "bicyclette" &&
                                <h2 className={`home__content__block__title ${currentBreakpoint}`}>
                                    {translations[locale].bicyclette.introduction.title}
                                </h2>
                            }
                            <p className="home__content__block__text"
                                dangerouslySetInnerHTML={{ __html: service?.text }}>
                            </p>
                            <div className="home__content__block__links">
                                <Link className="button button--arrow"
                                    to="/contact">
                                    <div className="button--arrow__icon">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                    {translations[locale].header.contact}
                                </Link>
                                {service?.networks.length > 0 &&
                                    <React.Fragment>
                                        {service?.networks.map((network, index) => (
                                            <a key={index}
                                                className="button button--more"
                                                href={network.url}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={network.icon} />
                                                {network.title}
                                            </a>
                                        ))}
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                        {service?.video && cookies.youtubeCookies &&
                            <div className="home__content__block">
                                <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                    {translations[locale].home.video.title}
                                </h1>
                                <iframe className="home__content__block__iframe"
                                    width="560"
                                    height="315"
                                    src={service?.video}
                                    title={service?.title}
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowfullscreen></iframe>
                            </div>
                        }
                        <div className="home__content__block">
                            <h1 className={`home__content__block__title ${currentBreakpoint}`}>
                                {translations[locale].home.gallery.title}
                            </h1>
                            <div className="home__content__block__gallery">
                                {service?.gallery.map(photo => (
                                    <div key={photo.index}
                                        className={`home__content__block__gallery__image ${currentBreakpoint}`}>
                                        <img className="home__content__block__gallery__image__img image-loading"
                                            src={photo.thumbnail}
                                            alt={photo.alt}
                                            loading="lazy"
                                            onClick={() => {
                                                setIndex(photo.index);
                                                setDisplayLightbox(true);
                                            }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={`service__pagination ${currentBreakpoint}`}>
                            <Link className="button button--more"
                                to={`/universes/${parseInt(service?.index) === 1 ? universes[universes.length - 1].id : universes[service?.index - 2].id}`}
                                onClick={() => setOnPage(false)}>
                                <FontAwesomeIcon icon="fa-solid fa-arrow-left" />
                                {universes.find(item => item.id === (parseInt(service?.index) === 1 ? universes[universes.length - 1].id : universes[service?.index - 2].id)).title}
                            </Link>
                            <Link className="button button--more"
                                to={`/universes/${parseInt(service?.index) === universes.length ? universes[0].id : universes[service?.index].id}`}
                                onClick={() => setOnPage(false)}>
                                {universes.find(item => item.id === (parseInt(service?.index) === universes.length ? universes[0].id : universes[service?.index].id)).title}
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {
                displayLightbox &&
                <Lightbox
                    gallery={service?.gallery}
                    activeIndex={index}
                    setIndex={setIndex}
                    setDisplay={setDisplayLightbox} />
            }
        </React.Fragment >
    )
}