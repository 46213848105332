import React, { useContext, useEffect, useState, Component } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Intro() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { page, setPage } = useContext(Title);
    const { universes, finished, setFinished } = useContext(Content);

    const time = 2250;

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 100));
        }, time / 125);

        return () => clearInterval(timer);
    }, []);

    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        if (finished) {
            setHidden(true);
        } else {
            document.body.classList.add("no-scroll");
        }

        const timer = setTimeout(() => {
            setFinished(true);
            hideIntro();
        }, time);

        return () => clearTimeout(timer);
    }, []);

    const hideIntro = () => {
        setTimeout(() => {
            setHidden(true);
            document.body.removeAttribute("class");
        }, 2000);
    }

    return (
        <div className={`intro ${finished ? "shrink" : ""} ${hidden ? "hidden" : ""} page--${page}`}>
            <div className="intro__container">
                <div className={`intro__logo ${finished ? "finished" : ""}`}>
                    <img className="intro__logo__img"
                        src="https://bracson.b-cdn.net/Logo/logo_cross.png"
                        alt="Bracson" />
                </div>
                <div className={`intro__progress ${finished ? "finished" : ""}`}>
                    <h1 className="intro__progress__background">
                        Bracson
                    </h1>
                    <h1 className="intro__progress__foreground"
                        style={{ width: `${progress}%` }}>
                        Bracson
                    </h1>
                </div>
            </div>
            <button className={`button button--more intro__button ${finished ? "finished" : ""}`}
                onClick={() => {
                    setFinished(true);
                    hideIntro();
                }}>
                {translations[locale].intro.access}
            </button>
        </div>
    )
}