import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function PageNotFound() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);

    const [onPage, setOnPage] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("not-found");
        setAncestors([
            { name: translations[locale].not_found.label, url: "/not-found" },
        ]);
    }, []);

    return (
        <div className={`home init ${onPage ? "init--achieved" : ""}`}>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].not_found.label}
                    </title>
                    <meta name="description"
                        content={translations[locale].not_found.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].not_found.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className="container">
                <div className={`home__content container__content home__content--page ${currentBreakpoint}`}>
                    <div className="home__content__block home__content__block--page">
                        <div className="notfound">
                            <h1 className="notfound__title">
                                {translations[locale].not_found.title}
                            </h1>
                            <p className="notfound__text"
                                dangerouslySetInnerHTML={{ __html: translations[locale].not_found.text }}>
                            </p>
                            <div className="notfound__buttons">
                                <Link className="button button--arrow"
                                    to="/">
                                    <div className="button--arrow__icon">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                    {translations[locale].header.home}
                                </Link>
                                <Link className="button button--arrow"
                                    to="/universes">
                                    <div className="button--arrow__icon">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                    {translations[locale].header.universe}
                                </Link>
                                <Link className="button button--arrow"
                                    to="/contact">
                                    <div className="button--arrow__icon">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                    {translations[locale].header.contact}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}