import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { Locale, Window } from '../App';

export default function Footer() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);

    const footerMapLinks = [
        {
            label: translations[locale].header.home,
            url: "/",
        },
        {
            label: translations[locale].header.bicyclette,
            url: "/universes/bicyclette-smoothie-energie",
        },
        {
            label: translations[locale].header.universe,
            url: "/universes",
        },
        {
            label: translations[locale].header.shop,
            url: "/shop",
        },
        {
            label: translations[locale].header.contact,
            url: "/contact",
        },
    ];

    const footerUsefulLinks = [
        {
            label: translations[locale].footer.legal_notice,
            url: "/legal",
        },
        {
            label: translations[locale].footer.terms,
            url: "/terms-and-conditions",
        },
        {
            label: translations[locale].footer.cookie,
            url: "/cookies",
        },
    ];

    return (
        <div className={`footer ${currentBreakpoint}`}>
            <div className="footer__top">
                <Link className="footer__top__logo"
                    to="/"
                    onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                    <img className="footer__top__logo__image"
                        src="https://bracson.b-cdn.net/Logo/logo.png"
                        alt="Bracson"
                        loading="lazy" />
                </Link>
                © Bracson, 2023.<br />
                {translations[locale].footer.all_rights_reserved}
            </div>
            <div className={`footer__links ${currentBreakpoint}`}>
                <div className={`footer__links__block ${currentBreakpoint}`}>
                    <h1 className="footer__links__block__title">
                        {translations[locale].footer.sitemap}
                    </h1>
                    <div className={`footer__links__block__items ${currentBreakpoint}`}>
                        {footerMapLinks.map((link, index) => (
                            <Link key={index}
                                className="footer__links__block__items__item"
                                to={link.url}
                                onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                                {link.label}
                            </Link>
                        ))}
                    </div>
                </div>
                <div className={`footer__links__block ${currentBreakpoint}`}>
                    <h1 className="footer__links__block__title">
                        {translations[locale].footer.useful_links}
                    </h1>
                    <div className={`footer__links__block__items ${currentBreakpoint}`}>
                        {footerUsefulLinks.map((link, index) => (
                            <Link key={index}
                                className="footer__links__block__items__item"
                                to={link.url}>
                                {link.label}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            <div className={`footer__links ${currentBreakpoint}`}>
                <div className={`footer__links__block ${currentBreakpoint}`}>
                    <div className={`footer__links__block__items ${currentBreakpoint}`}>
                        <a className="footer__links__block__items__item footer__links__block__items__item--powered"
                            href="https://linkedin.com/in/benoit-ricard-dev"
                            target="_blank"
                            rel="noopener noreferrer">
                            {translations[locale].footer.powered_by}
                            <span> Benoît Ricard</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}